<template>
  <div class="text itemContainer" :class="[snippetClass, extraClass, bannerImageMargin]" v-show="(text && coursesPublished) || edit">
    <div :class="[itemTextContainerClass, bannerTitleMargin]" :style="floatText">
      <h3 style="display:flex">
        {{ $t(header) }} <span class="required" v-show="required && edit">*</span>
        <ExtraInfo :id="header" v-if="edit"></ExtraInfo>
        <div v-if="showActionButtons && index == 'programOverview.practicalInformation'" style="margin-left: auto;">
          <span style="font-size: 12px"> {{ $t("general.action") }}: </span>
          <select style="font-size: 12px" class="subfilter" @change="triggerActionOnParent($event)">
            <option :key="0" :value="0">Select action Exchange CC ONLY</option>
            <option :key="1" :value="'reset_prac_info'">Reset Practical Information</option>
            <option :key="2" :value="'reset_prac_info_ext'">Reset Practical Information (extended)</option>
          </select>
        </div>
        <div v-if="showActionButtons && index == 'programOverview.studentCounselling'" style="margin-left: auto;">
          <span style="font-size: 12px"> {{ $t("general.action") }}: </span>
          <select style="font-size: 12px" class="subfilter" @change="triggerActionOnParent($event)">
            <option :key="0" :value="0">Select action Exchange CC ONLY</option>
            <option :key="1" :value="'reset_fg'">Reset Student Guidance (FG)</option>
            <option :key="2" :value="'reset_foo'">Reset Student Guidance (FOO)</option>
            <option :key="3" :value="'reset_fmr'">Reset Student Guidance (FMR)</option>
            <option :key="4" :value="'reset_fbe'">Reset Student Guidance (FBE)</option>
            <option :key="5" :value="'reset_fbsv'">Reset Student Guidance (FBSV)</option>
            <option :key="6" :value="'reset_ft'">Reset Student Guidance (FT)</option>
            <option :key="7" :value="'reset_fdmci_amfi'">Reset Student Guidance (FDMCI/AMFI)</option>
            <option :key="8" :value="'reset_fdmci_ict'">Reset Student Guidance (FDMCI/ICT)</option>
            <option :key="9" :value="'reset_fdmci_cocb'">Reset Student Guidance (FDMCI/COCB)</option>
            <option :key="10" :value="'reset_fdmci_cmd'">Reset Student Guidance (FDMCI/CMD)</option>
          </select>
        </div>
      </h3>
      <div>
        <template v-if="edit">
          <div :id="headerId">
            <div v-html="text" />
          </div>
          <div class="karakters ms-metadata">
            <span v-if="maxChar !== null" :class="{ error: currentCharLeftCount < 0 }"> {{ $t("editPageValidations.maxCharacters") }} {{ currentCharLeftCount }} </span>
          </div>
          <div v-if="isNewStyle && bannerImagePosition">
            <extra-image-upload @fileChange="loadImage($event)" :value="uploadedImage"> </extra-image-upload>
            <div v-if="!existingImage && extraImage.image">
              <vue-cropper ref="cropper" :src="extraImage.image" :zoomOnWheel="false" :aspect-ratio="17 / 9" />

              <div class="buttons control">
                <button class="button is-small" @click="cropImage">{{ $t("general.cropImage") }}</button>
                <button class="button is-small" @click="removeImage">{{ $t("general.removeImage") }}</button>
                <span class="stretch" />
              </div>
            </div>
            <div v-if="existingImage">
              <img class="bannerImage halfWidthImage" :src="existingImage" />
              <div class="buttons control">
                <button class="button is-small" @click="removeImage">{{ $t("general.removeImage") }}</button>
              </div>
            </div>
          </div>
        </template>
        <ReadMore :maxHeight="readMoreMaxHeight" v-if="isProgramOverview && !edit" @toggle="toggleReadMore" :arrowStyle="existingImage && isNewStyle ? 'right' : 'down'" :margin="margin">
          <div :class="itemTextClass" v-html="text" />
        </ReadMore>
        <div v-if="!isProgramOverview && !edit">
          <div :class="itemTextClass" v-html="text" />
        </div>
      </div>
    </div>
    <div :class="imageClass" v-if="!edit && isNewStyle" :style="floatImage">
      <img class="bannerImage" v-if="existingImage && isNewStyle" :src="existingImage" :style="floatImage" />
    </div>
  </div>
</template>

<script>
/*eslint-disable */
import settings from "../../assets/settings.json";
import Item from "../../mixins/Item.js";
import FroalaEditor from "froala-editor";
import ReadMore from "./ReadMore";
import ExtraInfo from "./ExtraInfo";
import ExtraImageUpload from "../ExtraImageUpload.vue";
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";

var language = "nl_NL";
if (location.host.indexOf("coursecatalogue") != -1) {
  language = "en_GB";
}

window.WEBSPELLCHECKER_CONFIG = {
  autoSearch: true,
  autoDestroy: true,
  serviceId: settings.webspellcheckerkey,
  lang: language,
};

export default {
  name: "text-item",
  components: {
    ReadMore,
    ExtraInfo,
    ExtraImageUpload,
    VueCropper,
  },
  mixins: [Item],
  props: {
    required: Boolean,
    maxChar: Number,
    isProgramOverview: Boolean,
    readMoreMaxHeight: Number,
    spellCheckLang: String,
    bannerImage: String,
    bannerImagePosition: String,
    infoType: String,
    connect: Boolean,
    isNewStyle: Boolean,
    index: String,
    showActionButtons: Boolean,
    coursesPublished: Boolean
  },
  data: function() {
    return {
      existingImage: null,
      config: null,
      text: "",
      isReadMore: false,
      editor: null,
      extraImage: {
        name: "",
        image: null,
        croppedImage: "",
        backupImage: null,
      },
      uploadedImage: null,
    };
  },
  computed: {
    margin() {
      if (this.existingImage) {
        if (this.index == "programOverview.programmeDescription") {
          return 35;
        } else {
          return 25;
        }
      }
      return 0;
    },
    floatText() {
      if (this.isReadMore || !this.isNewStyle) return "width: 100%";
      if (!this.existingImage || this.edit) return "";
      var floatdirection;
      if (this.bannerImagePosition === "right") {
        floatdirection = "left";
      } else {
        floatdirection = "right";
      }
      return "float: " + floatdirection + "; margin-" + floatdirection + ": 10px;";
    },
    floatImage() {
      if (this.isReadMore) return "display: none";
      if (!this.existingImage || this.edit) return "";
      return "float:" + this.bannerImagePosition + ";";
    },
    imageClass() {
      if (this.edit || this.existingImage) return "bannerImageContainer";
      else if (this.existingImage) return "bannerImageContainer halfWidthImage";
      else return "";
    },
    itemTextContainerClass() {
      if (this.edit) return "itemTextContainer";
      else if (this.existingImage) return "itemText halfWidthText";
      else return "itemText";
    },
    itemTextClass() {
      if (this.existingImage) return "bannerText";
      else return "view";
    },
    snippetClass() {
      return this.edit ? `${this.$i18n.locale}` : "";
    },
    bannerImageMargin() {
      if (!this.existingImage || this.edit) return "";
      return this.connect ? "bannerImageMargins" : "";
    },
    bannerTitleMargin() {
      if (!this.existingImage || this.edit) return "";
      return !this.connect ? "bannerTitleMargin" : "";
    },
    headerId() {
      var headerArray = this.header.split(".");
      return headerArray[headerArray.length - 1];
    },
    currentCharLeftCount: function() {
      if (this.text === null) {
        return this.maxChar;
      }
      var x = this.$(document.createElement("DIV"));
      var charCount = x
        .html(this.text)
        .text()
        .replace(/\s/g, ""); //strip html and remove whitespaces
      return this.maxChar - charCount.length;
    },
  },
  watch: {
    text: function() {
      this.checkRequiredness();
    },
    edit: function(value) {
      var model = this;
      if (value) {
        this.$nextTick(() => {
          model.loadEditor();
        });
      }
    },
  },
  created() {
    this.existingImage = this.bannerImage;
    var model = this;
    this.$on("locale", (lang) => {
      model.config.language = lang;
      model.loadEditor();
    });
    var toolbar = {
      moreText: {
        buttons: ["bold", "italic", "underline", "clearFormatting", "strikeThrough", "subscript", "superscript"],
        buttonsVisible: 4,
      },
      moreParagraph: {
        buttons: ["formatOLSimple", "formatUL", "paragraphFormat", "alignLeft", "alignCenter", "alignRight", "alignJustify", "outdent", "indent"],
      },
      moreRich: {
        buttons: ["insertLink", "insertImage", "insertFile", "insertVideo", "insertTable", "specialCharacters", "insertHR"],
        buttonsVisible: 5,
      },
      moreMisc: {
        buttons: ["undo", "redo", "fullscreen", "help"],
        buttonsVisible: 4,
      },
    };
    if (this.header == "video") {
      toolbar = ["insertVideo"];
    }

    this.config = {
      events: {
        blur: () => {
          model.$emit("blur");
        },
        contentChanged: () => {
          if (model.editor.core.isEmpty()) {
            model.text = "";
          } else {
            var html = model.editor.html.get();
            if (html.includes("<iframe") || html.includes("<img") || html.includes("<video")) {
              model.text = html;
            } else {
              var temp = document.createElement("div");
              temp.innerHTML = html;
              if (temp.innerText.trim() != "") {
                model.text = html;
              } else {
                model.text = "";
              }
            }
          }
          model.$emit("change", model.header, model.text);
          model.onDirty();
        },
        "html.get": (html) => {
          var textualHtml = html;
          var currentHtml = model.$.parseHTML(html);

          while (currentHtml.length == 1 && currentHtml[0].nodeName == "DIV" && currentHtml[0].attributes.length > 0 && currentHtml[0].attributes[0].name.startsWith("data-v-")) {
            textualHtml = currentHtml[0].innerHTML;
            currentHtml = model.$.parseHTML(textualHtml);
          }
          return textualHtml;
        },
        initialized: () => {
          WEBSPELLCHECKER.init({
            container: document.getElementById(this.headerId),
            lang: model.spellCheckLang ? model.spellCheckLang : language,
          });
        },
      },
      spellcheck: false,
      initOnClick: true,
      placeholderText: "",
      toolbarButtons: toolbar,
      imageUpload: true,
      imagePaste: true,
      videoUpload: false,
      fileUpload: true,
      imageEditButtons: ["imageReplace", "imageAlign", "imageRemove", "imageDisplay", "imageAlt", "imageSize"],
      tableEditButtons: ["tableHeader", "tableRows", "tableColumns", "-", "tableCells", "tableCellHorizontalAlign", "tableRemove"],
      fileInsertButtons: ["fileBack", "|"],
      language: this.$i18n.locale,
      imageDefaultAlign: "left",
      wordPasteModal: false,
      wordDeniedAttrs: ["style"],
      attribution: false,
      key: settings.froalaApi,
      imageUploadURL: "/api/blob/UploadImage",
      imageMaxSize: 5 * 1024 * 1024,
      imageAllowedTypes: ["jpeg", "jpg", "png", "gif"],
      fileAllowedTypes: ["*"],
      fileUploadURL: "/api/blob/UploadImage/attachment",
    };
  },
  mounted() {
    var model = this;
    this.checkRequiredness();
    this.$nextTick(() => {
      model.loadEditor();
    });
  },
  methods: {
    loadImage(imgFile) {
      this.extraImage.backupImage = imgFile.image;
      this.extraImage.name = imgFile.name;
      this.extraImage.image = imgFile.image;
      if (this.$refs.cropper) {
        this.$refs.cropper.replace(imgFile.image);
      }
      this.existingImage = null;
      this.uploadedImage = null;
      this.$emit("imgUpload", this.infoType);
    },
    triggerActionOnParent(event) {
      var action = event.target.value;
      this.$emit("triggerAction", action);
    },
    cropImage() {
      if (this.extraImage.image === null) return;
      this.extraImage.backupImage = this.extraImage.image;
      this.extraImage.image = this.$refs.cropper.getCroppedCanvas().toDataURL();
      if (this.$refs.cropper) {
        this.$refs.cropper.replace(this.extraImage.image);
      }
      this.existingImage = this.extraImage.image;
      this.changeBannerImage(this.infoType, this.extraImage.name, this.extraImage.image);
      this.extraImage.image = null;
    },
    removeImage() {
      this.extraImage.image = null;
      this.extraImage.name = "";
      if (this.$refs.cropper) {
        this.$refs.cropper.replace(null);
      }
      this.changeBannerImage(this.infoType, "", null);
      this.existingImage = null;
    },
    changeBannerImage(type, name, image) {
      var payload = {
        type: type,
        name: name,
        image: image,
      };
      this.$emit("imgChange", payload);
    },
    onSave() {
      this.revert = this.text;
    },
    toOriginal() {
      this.text = this.revert;
      if (this.editor) {
        this.editor.html.set(this.text);
      }
    },
    loadEditor() {
      if (this.edit) {
        if (this.editor && this.editor.destroy) {
          this.editor.destroy();
        }
        this.editor = new FroalaEditor(`#${this.headerId}`, this.config);
      }
    },
    checkRequiredness() {
      if (this.required) {
        if (this.currentCharLeftCount === this.maxChar) {
          this.$emit("requiredFieldNotFilled", this.header);
        } else {
          this.$emit("requiredFieldFilled", this.header);
        }
      }
    },
    toggleReadMore(value) {
      this.isReadMore = value;
    },
  },
};
</script>
<style lang="less" scoped>
@import "../../assets/item.less";

.buttons {
  width: 100%;
  margin-top: 5px;
  align-content: center;

  text-align: justify;
  -ms-text-justify: distribute-all-lines;
  text-justify: distribute-all-lines;

  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 1rem;
  position: relative;
  text-align: left;
}


.button {
  background-color: white;
  border-color: #dbdbdb;
  border-width: 1px;
  color: #25167a;
  cursor: pointer;
  justify-content: center;
  padding-bottom: calc(0.375em - 1px);
  padding-left: 0.75em;
  padding-right: 0.75em;
  padding-top: calc(0.375em - 1px);
  text-align: center;
  white-space: nowrap;

  &.is-small {
    border-radius: 3px;
    font-size: 0.75rem;
    border: 1px solid #dbdbdb;
    height: 2.25em;
    display: inline-block;
  }
}

.button:focus {
  outline: 0;
}

.halfWidthImage {
  display: inline-block;
  width: 60%;
}

.halfWidthText {
  margin-top: 20px;
  display: inline-block;
  width: 40%;
}

.bannerImageMargins {
  margin-top: -25px;
}

.bannerTitleMargin {
  margin-top: -10px;
}
</style>
