<!--A modification using max height based on https://github.com/orlyyani/read-more-->
<template>
	<div class="container-div">
		<div ref="text" :class="dataClass" :style="{height: computedHeight, display: isDisplayed}">
			<slot></slot>
		</div>
		<div v-show="this.originalHeight > maxHeight" :style="calculateMargin">
			<a role="button" :class="readMoreClass" v-show="!isReadMore" @click="toggleReadMore()" >
				<span  :style="fontColor">{{$t('general.readMore')}}</span> <span v-if="arrowStyle == 'right'" :style="fontColor">&rarr;</span>
			</a>
			<a role="button" :class="readLessClass" v-show="isReadMore" @click="toggleReadMore()">
				<span  :style="fontColor">{{$t('general.readLess')}}</span> <span v-if="arrowStyle == 'right'" :style="fontColor">&larr;</span>
			</a>
		</div>
	</div>
</template>
<script>
export default {
	props: {
		maxHeight: {
			type: Number,
			default: 200
		},
		margin: Number,
		grayblock: Boolean,
		display: Boolean,
		arrowStyle: String
	},
	data() {
		return {
			isReadMore: false,
			originalHeight: null
		};
	},
	computed: {
		calculateMargin() {
			if (!this.margin) return "";
			else {
				return "margin-top: " + this.margin + "px;";
			}
		},
		readMoreClass() {
			return this.arrowStyle !== "right" ? "read readMore" : "cursorChange"
		},
		readLessClass() {
			return this.arrowStyle !== "right" ? "read readLess" : "cursorChange"
		},
		fontColor() {
			return "color: " + (this.arrowStyle !== "right" ? "#000" : "#25167a") + ";"
		},
		isDisplayed() {
			return !this.isReadMore && this.display ? "none" : "block";
		},
		computedHeight() {
			if (this.originalHeight > this.maxHeight && !this.isReadMore) {
				var newHeight = (this.maxHeight - (this.maxHeight % 1)) - 95;
				if (newHeight < 0) {
					newHeight = 0;
				}
				return newHeight + "px";
			}
			return "100%";
		},
		dataClass() {
			return this.grayblock && this.isReadMore ? "is-gray-block data-clean text-content" : "data-clean text-content";
		}
	},
	mounted() {
		this.initReadMore();
	},
	methods: {
		initReadMore(){
			this.originalHeight = this.$refs.text.clientHeight;
			if (this.display) this.originalHeight = this.maxHeight + 1;
		},
		toggleReadMore() {
			this.isReadMore = !this.isReadMore;
			this.$emit("toggle", this.isReadMore);
		}
	}
};
</script>
<style lang="less" scoped>
#app {
	div.data-clean {
		overflow: hidden;
	}

	a.read {
		color: #000 !important;
		background-repeat: no-repeat;
		cursor: pointer;
		padding-left: 30px;
		border-bottom: 1px solid #dddddd;
		background-size: 18px 18px;
		background-position: 0px 5px;
		display: block;
	}

	.readMore {
		background-image: url(/arrowdown.png);
	}

	.readLess {
		background-image: url(/arrowup.png);
	}
	.cursorChange {
		cursor: pointer;
	}
}
</style>
<style lang="less">
#app {
	.is-gray-block {
		overflow-x: auto !important;
		padding: 5px 15px;
		background-color: #f2f2f2;
		margin-bottom: 12px !important;
	}

	.is-gray-block > div {
		padding: 5px 0px;
	}

	.is-gray-block .data-title h3 {
		font-family: "Open Sans", Helvetica, Arial, sans-serif;
		line-height: 1.5;
		font-size: 15px;
		font-weight: 400;
		margin: 0px;
		text-transform: none;
		letter-spacing: normal;
		color: black;
	}

	.is-gray-block .data-content {
		font-size: 15px;
		font-family: "Open Sans", Helvetica, Arial, sans-serif;
		font-weight: 400;
		line-height: 1.5;
		color: #666;
	}
}
</style>