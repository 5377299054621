<template>
  <div v-if="edit" :class="classes">
    <h3 class="first">
      {{ $t(header) }}
      <ExtraInfo :id="header"></ExtraInfo>
    </h3>
    <div class="item view" v-html="text"></div>
  </div>
  <div v-else-if="!hideForStudents">
    <h3 class="first">{{ $t(header) }}</h3>
    <div class="item view" v-html="text"></div>
  </div>
</template>

<script>
import Item from "../../mixins/Item";
import ExtraInfo from "./ExtraInfo";

export default {
  name: "readonly-item",
  components: {
    ExtraInfo,
  },
  props: {},
  mixins: [Item],
  data: function() {
    return {
      text: "",
    };
  },
  methods: {
    onSave() {},
    toOriginal() {},
  },
  computed: {
    classes() {
      return this.edit ? `${this.$i18n.locale} ${this.extraClass} readonly` : "";
    },
  },
  created() {
    if (!this.value || this.value === "") {
      this.text = `<i>${this.$t("general.noValue")}</i>`;
    } else {
      this.text = this.value;
    }
  },
};
</script>
<style lang="less" scoped>
@import "../../assets/item.less";

.readonly {
  .snippet("");
  &::before {
    content: "Read-only";
  }

  &.sis {
    &.nl::before {
      content: "Geïmporteerd uit SIS";
    }
    &.en::before {
      content: "Imported from SIS";
    }
  }
}
</style>
