<template>
    <transition name="modal">
        <div class="modal-mask">
            <div class="modal-wrapper" @click="$emit('close')">
                <div class="modal-container" @click.stop>
                    <div class="modal-header">
                        <h2>{{$t('editorbar.revertConfirm.header')}}</h2>
                    </div>
                    <div class="modal-body">
                        <p>{{$t('editorbar.revertConfirm.body')}}</p>
                        <p>{{$t('editorbar.revertConfirm.body2')}}</p>
                    </div>
                    <div class="modal-footer">
                        <slot name="footer">
                            <button class="modal-default-button greenButton" @click="$parent.onRevert()" type="button">{{$t('editorbar.revertConfirm.yesButton')}}</button>
                            <button class="modal-default-button whiteButton" @click="$parent.showModal = false" type="button">{{$t('editorbar.revertConfirm.noButton')}}</button>
                        </slot>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>
<script>
export default {
	name: "revertConfirmModal"
};
</script>
<style lang="less" scoped>
    #app {
        .modal-mask
        {
            position: fixed;
            z-index: 9998;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.5);
            display: table;
            transition: opacity 0.3s ease;
        }

        .modal-wrapper {
            display: table-cell;
            vertical-align: middle;
        }

        .modal-container {
            max-width: 600px;
            margin: 0px auto;
            padding: 10px 20px;
            background-color: #fff;
            border-radius: 2px;
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
            transition: all 0.3s ease;
            font-family: Helvetica, Arial, sans-serif;
            max-height: 80vh;
            margin-top: 10%;
            margin-bottom: 10%;
            overflow-y: auto;
        }

        .modal-body {
            margin: 5px 0;
        }

        .modal-default-button {
            float: right;
        }

        /*
        * The following styles are auto-applied to elements with
        * transition="modal" when their visibility is toggled
        * by Vue.js.
        *
        * You can easily play with the modal transition by editing
        * these styles.
        */

        .modal-enter {
            opacity: 0;
        }

        .modal-leave-active {
            opacity: 0;
        }

            .modal-enter .modal-container,
            .modal-leave-active .modal-container {
                -webkit-transform: scale(1.1);
                transform: scale(1.1);
            }

        h2 {
            color: #25167a;
            font-size: 20px;
            text-align: left;
            font-weight: 600;
            text-transform: uppercase;
            position: relative;
            margin: 0;
            padding: 0;
            line-height: 60px;
            display: block;
            font-family: "OpenSans Regular", "Segoe UI Semilight", "Segoe UI", "Segoe", Tahoma, Helvetica, Arial, sans-serif;
        }

            h2.smaller {
                text-align: left;
                font-size: 18px;
            }

        h3 a {
            color: #25167a;
        }

        .left {
            text-align: left;
        }

        .greenButton,
        .whiteButton{
            border: 0;
            box-shadow: none;
            font-size: 12px;
            font-weight: 500;
            border-radius: 5px;
            padding: 10px 10px;
            margin: 0 5px;
            cursor: pointer;
        }
        .greenButton {
                background-color: #007C68;
            border-left-color: #007C68;
            border-right-color: #007C68;
            color: #fff;
        }
        .whiteButton {
            color: #007C68;
            border-left-color: #007C68;
            border-right-color: #007C68;
            background-color: #eee;
        }

        h3.small {
            text-transform: none;
            font-size: 11pt;
            line-height: 1.6em;
            font-weight: normal;
        }
        .modal-header,
        .modal-body,
        .modal-footer {
            padding-left: 0;
            padding-right: 0;
        }

        .modal-header {
            padding: 0 !important;
        }

        .modal-default-button {
            font-size: 15px !important;
            margin-left: 10px !important;
            margin-right: 0 !important;
        }
    }
</style>
