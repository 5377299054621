/*eslint-disable */

import OverviewHeader from "common/components/General/OverviewHeader.vue";
import SideMenu from "common/components/General/SideMenu.vue";
import Spinner from "common/components/General/Spinner.vue";
import EditorBar from "../components/EditPages/EditorBar.vue";
import TextItem from "../components/EditPages/TextItem.vue";
import Readonly from "../components/EditPages/Readonly.vue";
import { faCommentsDollar } from "@fortawesome/free-solid-svg-icons";

export default {

    components: {
        OverviewHeader,
        SideMenu,
        Spinner,
        EditorBar,
        TextItem,
        Readonly
    },
    data: function () {
        return {
            items: [],
            isEditing: false,
            isDirty: false,
            isSaving: false,
            originalEditorialState: "",
            saveMessage: {
                type: "",
                requiredFieldsNotFilled: []
            },
            autoSaveTimerId: null,
            link: {
                "en": "<a href='" + "/po" + this.$route.path + "/100000247" + "'>this page</a>",
                "nl": "<a href='" + "/po" + this.$route.path + "/100000247" + "'>deze pagina</a>"
            }
        };
    },
    created() {
        var model = this;
        this.$event.$on("dirty", () => {
            model.isDirty = true;
            model.saveMessage.type = "";
            window.onbeforeunload = () => {
                return "";
            };
        });
        this.$event.$on("revert", () => {
            model.isDirty = false;
            model.saveMessage.type = "";
            model.clearAutoSave(model);
        });
        this.$event.$on("save", () => {
            model.isDirty = false;
            model.clearAutoSave(model);
            window.onbeforeunload = () => {
                return "";
            };
        })
    },
    methods: {
        clearAutoSave(model) {
            if (model.autoSaveTimerId != null) {
                clearInterval(model.autoSaveTimerId);
                model.autoSaveTimerId = null;
            }
        },
        updateSave(item, value) {
            this.items[item].set(value);
        },
        onSwitchView() {
            this.isEditing = !this.isEditing;
        },
        onDirty() {
            var model = this;
            model.isDirty = true;
            window.onbeforeunload = () => {
                return "";
            };
        },
        requiredFieldFilled(field) {
            var model = this;
            var index = model.saveMessage.requiredFieldsNotFilled.indexOf(field);
            if (index > -1) {
                model.saveMessage.requiredFieldsNotFilled.splice(index, 1);
            }
        },
        requiredFieldNotFilled(field) {
            if (this.saveMessage.requiredFieldsNotFilled.indexOf(field) === -1) {
                this.saveMessage.requiredFieldsNotFilled.push(field);
            }
        },
        setValue(valuePath, value, data) {
            var pathArray = valuePath.split(".");
            while (pathArray.length - 1) {
                var n = pathArray.shift();
                if (!(n in data))
                    data[n] = {};
                data = data[n];
            }
            data[pathArray[0]] = value;
        },
        getValue(path, data) {
            var pathNodes = path.split(".");
            var allNodes = data;
            while (pathNodes.length) {
                var n = pathNodes.shift();
                if (!(n in allNodes)) return;
                allNodes = allNodes[n];
            }
            return allNodes;
        },
        createText(data, header, valuePath, required, language = null, maxChar = null, extraClass = "", readMoreMaxHeight = 0, imageUrl = "", imgPos = null, connect = false, isNewStyle = false, coursesPublished = true, courseText = false) {
            var model = this;
            var value = model.getValue(valuePath, data);
            var set = v => {
                model.setValue(valuePath, v, data);
            };
            if (!value) {
                value = "";
                if (courseText) {
                    value += this.content.CourseText.details.Text;
                }
            }
            value = value.replace("*LINK*", this.link[language == "en_GB" ? "en" : "nl"])
            if (imgPos != null && header != "programOverview.programmeDescription") {
                readMoreMaxHeight = 190;
            } 
            return { type: "text", header, value, set, valuePath, required: required, maxChar, extraClass, bannerImage: imageUrl, bannerImagePosition: imgPos, infoType: valuePath, readMoreMaxHeight: readMoreMaxHeight, spellCheckLang: language, connect, isNewStyle, coursesPublished: coursesPublished };
        },
        createBannerText(data, header, valuePath, templateText, required, coursesPublished, language = null, maxChar = null, extraClass = "", readMoreMaxHeight = 0, imageUrl = "", imgPos = null, connect = false, isNewStyle = false) {
            var model = this;
            var value = model.getValue(valuePath, data);
            if (!value) value = "";
            var set = v => {
                model.setValue(valuePath, v, data);
            };
            if (imgPos != null) readMoreMaxHeight = 210;
            return { type: "banner-text", header, value, set, valuePath, templateText, coursesPublished, data, required: required, maxChar, extraClass, bannerImage: imageUrl, bannerImagePosition: imgPos, infoType: valuePath, readMoreMaxHeight: readMoreMaxHeight, spellCheckLang: language, connect, isNewStyle };
        },
        createLink(data, header, titleValuePath, linkValuePath, required, extraClass = "") {
            var model = this;
            var linkValue = model.getValue(linkValuePath, data);
            if (!linkValue) {
                linkValue = "";
            }
            var value = model.getValue(titleValuePath, data) + ";#;" + linkValue;
            var set = v => {
                model.setValue(linkValuePath, v, data);
            };
            if (!value) value = ";#;";
            return { type: "link", header, value, set, valuePath: linkValuePath, required: required, extraClass };
        },
        createReadOnly(data, header, valuePath, required, hideForStudents = false, extraClass = "") {
            var model = this;
            var value = model.getValue(valuePath, data);
            return { type: "readonly", header, value, valuePath, required, hideForStudents, extraClass };
        },
        createTable(data, header, valuePath, required, extraClass = "") {
            var model = this;
            var value = model.getValue(valuePath, data);
            return { type: "table", header, value, valuePath, required: required, extraClass };
        },
        createReadOnlyArray(data, header, valuePath, required, extraClass = "") {
            var model = this;
            var value = model.getValue(valuePath, data);
            return { type: "array", header, value, valuePath, required: required, extraClass };
        },
        createSelect(data, header, valuePath, required, options) {
            var model = this;
            var value = model.getValue(valuePath, data);
            var set = v => {
                model.setValue(valuePath, v, data);
            };
            return { type: "select", header, value, set, valuePath, required, options };
        },
        createPeople(data, header, valuePath, required) {
            var model = this;
            var value = model.getValue(valuePath, data);
            var set = v => {
                model.setValue(valuePath, v, data);
            };
            return { type: "people", header, value, set, valuePath, required: required };
        }
    }
}