<template>
  <div :class="isEditing ? 'sticky' : ''">
    <div :class="[futureEditMode ? 'futureEdit' : '', 'has-background-info has-text-light notification redactiealert']">
      <div>
        <span v-show="!isDirty">{{ $t("editorbar.redactiealert") }}</span>
        <div v-show="isDirty" class="changes">
          <font-awesome-icon icon="exclamation-circle" />
          <span>{{ $t("editorbar.changes") }}</span>
        </div>
      </div>
      <div class="controls field">
        <div class="control-group">
          <div v-if="input !== undefined" v-show="isEditing" class="control has-icons-left status">
            <div class="select is-small is-info">
                <select v-model="input" @change="onDirty">
                        <option value="Invoeren">{{ $t("editorbar.editorialState.enter") }}</option>
                        <option value="Goedkeuren">{{ $t("editorbar.editorialState.approve") }}</option>
                        <option value="Gereed" :disabled="!enableDoneOption">{{ $t("editorbar.editorialState.completed") }}</option>
                        <option v-if="isExchange" value="Publiceer alleen de titel">{{ $t("editorbar.editorialState.preview") }}</option>
                </select>
            </div>
            <div :class="['icon', 'is-small', 'is-left', 'redactiestatusOpleiding', programState]">
              <font-awesome-icon icon="circle" />
            </div>
          </div>
          <div class="control switch">
            <button @click="onSwitchView" class="button is-small switch">
              {{ isEditing ? $t("editorbar.showasstudent") : $t("editorbar.showaseditor") }}
            </button>
          </div>
        </div>
        <div class="control-group">
          <div v-show="!futureEditMode && isEditing && (isProgramOverview || isCourseOverview)" class="control">
            <font-awesome-icon style="cursor:pointer;" icon="recycle" :title="$t('editorbar.autoSave')" @click="onGetAutoSaves" />
          </div>
          <div v-show="futureEditMode || (isEditing && isCourseOverview && userIsCourseFinalEditor && input == 'Gereed' && !isDirty)" class="control">
            <font-awesome-icon style="cursor:pointer;" :icon="futureEditMode ? 'reply' : 'user-edit'" :title="$t(futureEditMode ? 'editorbar.backFromFutureEdit' : 'editorbar.futureEdit')" @click="onSwitchFuture" />
          </div>
          <div v-show="isEditing" class="control">
            <a class="button is-small" :href="helpLink" target="_blank">
              Help
            </a>
          </div>
          <div v-show="isEditing" class="control">
            <button class="button is-small" :class="isSaving ? ' is-loading' : ''" @click="onSave" :disabled="(!isDirty && saveClicked) || croppersOpen">
              {{ $t("editorbar.save") }}
            </button>
          </div>
          <div v-show="isEditing" class="control">
            <button class="button is-small" @click="onRevertClicked" :disabled="!isDirty">
              {{ $t("editorbar.revert") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="alert alert-primary" v-if="showCourseCatalogWarning">
      <div class="content">
        <i18n path="editorbar.exchangeWarningProgram" tag="label" v-if="isProgramOverview || isPhasingOverview">
          <template v-slot:pleaseNote>
            <strong>{{ $t("editorbar.pleaseNote") }}</strong>
          </template>
          <template v-slot:courseCatalog>
            <a target="_blank" :href="courseCatalogUrl">{{ $t("editorbar.courseCatalog") }}</a>
          </template>
        </i18n>
        <i18n path="editorbar.exchangeWarningCourse" tag="label" v-if="isCourseOverview">
          <template v-slot:pleaseNote>
            <strong>{{ $t("editorbar.pleaseNote") }}</strong>
          </template>
          <template v-slot:courseCatalog>
            <a target="_blank" :href="courseCatalogUrl">{{ $t("editorbar.courseCatalog") }}</a>
          </template>
        </i18n>
      </div>
    </div>
    <div class="notification is-warning" v-if="isEditing && saveMessage.type === 'warning'">
      <div class="content">
        {{ $t("editorbar.warning") }}
      </div>
    </div>
    <div class="notification is-danger" v-if="isEditing && saveMessage.type === 'error'">
      <div class="content">
        {{ $t("editorbar.requiredalert") }}
        <ul>
          <li v-for="(item, key) in saveMessage.requiredFieldsNotFilled" :key="key">{{ $tc(item, 0) }}</li>
        </ul>
        {{ $t("editorbar.requiredalert2") }}
      </div>
    </div>
    <div class="notification is-danger" v-if="isEditing && saveMessage.type === 'updateError'">
      <div class="content">
        {{ $t("editorbar.updateError") }}
      </div>
    </div>
    <div class="notification is-danger" v-if="isEditing && saveMessage.type === 'actionError'">
      <div class="content">
        {{ $t("editorbar.actionError") }}
      </div>
    </div>
    <div class="notification is-success" v-if="isEditing && saveMessage.type === 'actionSuccess'">
      <div class="content">
        {{ $t("editorbar.actionSuccess") }}
      </div>
    </div>
    <div class="notification is-loading" v-if="isEditing && saveMessage.type === 'actionLoading'">
      <div class="content">
        {{ $t("editorbar.actionLoading") }}
      </div>
      <template>
        <div class="spinner">
          <div class="triangles">
            <div class="tri invert"></div>
            <div class="tri invert"></div>
            <div class="tri"></div>
            <div class="tri invert"></div>
            <div class="tri invert"></div>
            <div class="tri"></div>
            <div class="tri invert"></div>
            <div class="tri"></div>
            <div class="tri invert"></div>
          </div>
        </div>
      </template>
    </div>
    <RevertConfirmModal v-if="showModal" />
  </div>
</template>
<script>
import RevertConfirmModal from "./RevertConfirmModal.vue";
import {camelCase} from "common/store/storeFunctions.js";

export default {
  name: "editorBar",
  components: {
    RevertConfirmModal,
  },
  props: {
    saveMessage: Object,
    isSaving: Boolean,
    isEditing: Boolean,
    isDirty: Boolean,
    value: String,
    showCourseCatalogWarning: Boolean,
    croppers: Number,
  },
  data: function() {
    return {
      revert: null,
      showModal: false,
      futureEditMode: false,
      saveClicked: false,
      programState: ""
    };
  },
  created() {
    window.scrollTo(0, 0);
    this.revert = this.input;
    this.programState = camelCase(this.input);
    // if using this when emitting use $event.$emit instead of $emit
    this.$event.$on("dirty", () => {
      window.onbeforeunload = () => {
        return "";
      };
    });
  },
  computed: {
    croppersOpen() {
      return this.croppers > 0;
    },
    helpLink() {
      return this.$i18n.locale == "en" ? "https://icthva.sharepoint.com/sites/coursecatalogue/SitePages/HELP-Course-Catalogue.aspx" : "https://icthva.sharepoint.com/sites/Studiegids/SitePages/Help-Studiegids.aspx";
    },
    isProgramOverview() {
      return this.$route.name.match("program-overview");
    },
    isPhasingOverview() {
      return this.$route.name.match("phasing-overview");
    },
    isCourseOverview() {
      return this.$route.name.match("course-overview");
    },
    enableDoneOption() {
        return (this.isCourseOverview && this.userIsCourseFinalEditor) || (this.isProgramOverview && this.userIsProgramFinalEditor);
    },
    courseCatalogUrl() {
      var language = this.$i18n.locale == "en" ? "nl" : "en";
      if (language == "nl") {
        if (this.isDev) {
          return "https://dev-studiegids.azurewebsites.net/";
        } else if (this.isAcc) {
          return "https://acc-studiegids.mijnhva.nl/";
        } else {
          return "https://studiegids.hva.nl/";
        }
      } else {
        if (this.isDev) {
          return "https://dev-coursecatalogue.mijnhva.nl/";
        } else if (this.isAcc) {
          return "https://acc-coursecatalogue.mijnhva.nl/";
        } else {
          return "https://coursecatalogue.amsterdamuas.com";
        }
      }
    },
    input: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
        this.programState = camelCase(value);
      },
    },
  },
  methods: {
    onSwitchView() {
      this.$emit("switchView");
    },
    onGetAutoSaves() {
      this.$router.push("/autosaves");
    },
    onSwitchFuture() {
      this.futureEditMode = !this.futureEditMode;
      this.$emit("switchFuture");
    },
    onSave() {
      this.saveClicked = true;
      if (!this.isDirty) {
        return;
      }
      this.revert = this.input;
      this.$emit("save"); //For saving
      this.$event.$emit("save"); //For telling components to set revert to new value
    },
    onDirty() {
      this.$event.$emit("dirty");
    },
    onRevertClicked() {
      this.showModal = true;
    },
    onRevert() {
      var model = this;
      model.showModal = false;
      if (this.isDirty) {
        model.input = model.revert;
        this.$event.$emit("revert");
        window.onbeforeunload = null;
      }
    },
  },
};
</script>
<style scoped lang="less">
.control.switch {
  margin-top: 1px;
}
.has-background-info {
  background-color: #007d68;
}
.has-background-info.futureEdit {
  background-color: #ecc600;
}
.has-text-light {
  color: whitesmoke;
}
.control {
  box-sizing: border-box;
  clear: both;
  font-size: 1rem;
  position: relative;
  text-align: left;
}

.alert {
  padding: 8px 12px;
  margin-left: -15px;
  margin-right: -15px;
  border-radius: 0.25rem;

  label {
    margin-bottom: 0;
  }
  a {
    color: inherit;
    text-decoration: underline;
  }
  a:hover {
    color: #001d3c;
  }
}
.control.has-icons-left .input.is-small ~ .icon,
.control.has-icons-left .select.is-small ~ .icon,
.control.has-icons-right .input.is-small ~ .icon,
.control.has-icons-right .select.is-small ~ .icon {
  font-size: 0.75rem;
}
.control.has-icons-left .icon.is-left {
  left: 0;
}
.icon {
  align-items: center;
  display: inline-flex;
  justify-content: center;
  height: 1.5rem;
  width: 1.5rem;
}
.control.has-icons-left .icon,
.control.has-icons-right .icon {
  height: 2.25em;
  pointer-events: none;
  position: absolute;
  top: 1px;
  width: 2.25em;
  z-index: 4;
}

.control.has-icons-left .input,
.control.has-icons-left .select select {
  padding-left: 2.25em;
}

.button {
  background-color: white;
  border-color: #dbdbdb;
  border-width: 1px;
  color: #25167a;
  cursor: pointer;
  justify-content: center;
  padding-bottom: calc(0.375em - 1px);
  padding-left: 0.75em;
  padding-right: 0.75em;
  padding-top: calc(0.375em - 1px);
  text-align: center;
  white-space: nowrap;

  &.is-small {
    border-radius: 3px;
    font-size: 0.75rem;
    border: 1px solid #dbdbdb;
    height: 2.25em;
    display: inline-block;
  }
}
button.button {
  padding-top: 0;
}
.button[disabled],
fieldset[disabled] .button {
  background-color: white;
  border-color: #dbdbdb;
  box-shadow: none;
  opacity: 0.5;
  cursor: not-allowed;
}
.sticky {
  top: 10px;
  position: sticky;
  z-index: 100;
}

.notification {
  border-radius: 4px;
}

.redactiealert {
  z-index: 100;
  padding: 8px;
  margin: 0px -10px;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  line-height: 27px;
  box-sizing: content-box;
  flex-direction: column;
  margin-bottom: 1em;

  .changes {
    display: inline-block;
    svg {
      font-size: 16px;
      margin-top: -3px;
    }
  }

  .controls {
    display: flex;
    flex-direction: column;

    .control-group {
      display: flex;
      justify-content: flex-end;

      &:not(:first-child) {
        margin-top: 0.5em;
        order: 1;
      }

      .control {
        margin-left: 0.75em;
      }
    }

    .button,
    select {
      color: #007d68;
    }
    .select {
      display: inline-block;
      max-width: 100%;
      position: relative;
      vertical-align: top;

      select {
        cursor: pointer;
        display: block;
        font-size: 1em;
        max-width: 100%;
        outline: none;
        line-height: 17px;
        border-radius: 2px;
        -moz-appearance: none;
        -webkit-appearance: none;
        align-items: center;
        border: 1px solid transparent;
        border-radius: 4px;
        box-shadow: none;
        display: inline-flex;
        padding: 3px 0 3px 27px;
        margin-top: 2px;
        justify-content: flex-start;
        position: relative;
        border-radius: 2px;
        height: 2.25em;
        border: 1px solid #dbdbdb !important;
        border-radius: 3px;
        margin-top: 1px !important;

        &:not([multiple]) {
          padding-right: 2.5em;
        }
      }
      &.is-small {
        border-radius: 2px;
        font-size: 0.75rem;
      }
      &:not(.is-multiple) {
        height: 2.25em;
        &:not(.is-loading):after {
          border-color: #3273dc;
          right: 1.125em;
          z-index: 4;
          border: 3px solid #007d68;
          border-radius: 2px;
          border-right: 0;
          border-top: 0;
          content: " ";
          display: block;
          height: 0.625em;
          margin-top: -0.3em;
          pointer-events: none;
          position: absolute;
          top: 50%;
          transform: rotate(-45deg);
          transform-origin: center;
          width: 0.625em;
        }
      }
      &.is-info select {
        border-color: #007d68;
        background-color: #fff;
      }
      .is-info:not(:hover):after {
        border-color: #007d68;
      }
    }

    a.button:hover {
      text-decoration: none;
      color: #007d68;
    }
  }

  @media only screen and (min-width: 720px) {
    .controls {
      flex-direction: row;

      .control-group:not(:first-child) {
        margin-top: 0em;
        order: -1;
      }
    }
  }
}

//desktop only
@media only screen and (min-width: 720px) {
  .redactiealert {
    flex-direction: row;
  }
}

//miniphones only
@media only screen and (max-width: 335px) {
  .redactiealert {
    font-size: 13px;
  }
}
</style>

<style scoped>
.spinner {
    margin-left: auto;
    margin-top: -2.5px;
}

.is-loading{
    display: flex;
}

@-webkit-keyframes pulse {
  0% {
    opacity: 1;
  }
  16.666% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes pulse {
  0% {
    opacity: 1;
  }
  16.666% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes pulse {
  0% {
    opacity: 1;
  }
  16.666% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
body {
  background: #fff;
}

.triangles {
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  height: 22.5px;
  width: 25px;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.acYearHeading .spinner .triangles {
  height: 18px;
}

.tri {
  position: absolute;
  -webkit-animation: pulse 1100ms ease-in infinite;
  -moz-animation: pulse 1100ms ease-in infinite;
  animation: pulse 1100ms ease-in infinite;
  border-top: 9px solid #25167a;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 0px;
}
.tri.invert {
  border-top: 0px;
  border-bottom: 9px solid #25167a;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
}
.tri:nth-child(1) {
  left: 10px;
}
.tri:nth-child(2) {
  left: 5px;
  top: 9px;
  -webkit-animation-delay: -183.3333333333ms;
  -moz-animation-delay: -183.3333333333ms;
  animation-delay: -183.3333333333ms;
}
.tri:nth-child(3) {
  left: 10px;
  top: 9px;
}
.tri:nth-child(4) {
  left: 15px;
  top: 9px;
  -webkit-animation-delay: -916.6666666667ms;
  -moz-animation-delay: -916.6666666667ms;
  animation-delay: -916.6666666667ms;
}
.tri:nth-child(5) {
  top: 18px;
  -webkit-animation-delay: -366.6666666667ms;
  -moz-animation-delay: -366.6666666667ms;
  animation-delay: -366.6666666667ms;
}
.tri:nth-child(6) {
  top: 18px;
  left: 5px;
  -webkit-animation-delay: -366.6666666667ms;
  -moz-animation-delay: -366.6666666667ms;
  animation-delay: -366.6666666667ms;
}
.tri:nth-child(7) {
  top: 18px;
  left: 10px;
  -webkit-animation-delay: -550ms;
  -moz-animation-delay: -550ms;
  animation-delay: -550ms;
}
.tri:nth-child(8) {
  top: 18px;
  left: 15px;
  -webkit-animation-delay: -733.3333333333ms;
  -moz-animation-delay: -733.3333333333ms;
  animation-delay: -733.3333333333ms;
}
.tri:nth-child(9) {
  top: 18px;
  left: 20px;
  -webkit-animation-delay: -733.3333333333ms;
  -moz-animation-delay: -733.3333333333ms;
  animation-delay: -733.3333333333ms;
}
</style>
