<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper" @click="$emit('close')">
        <div class="modal-container" @click.stop>
          <div class="modal-header">
            <h2>{{$t('phasingPageLabels.addPhasing')}}</h2>
            <div class="notification is-danger" v-if="error === 'createError'">
              <div class="content">
                {{$t('editorbar.updateError')}}
              </div>
            </div>
            <div class="notification is-danger" v-if="error === 'requiredError'">
              <div class="content">
                {{$t('general.requiredError')}}
              </div>
            </div>
          </div>

          <div class="modal-body">
            <h3>{{$t('commonLabels.phasing')}} <span class="required">*</span></h3>
            <select v-model="selectedPhasing" v-if="filteredPhasingOptions">
                <option value="0"></option>
                <option v-for="option in filteredPhasingOptions" :value="option.Key" :key="option.Key">{{option.Value}}</option>
            </select>
            <h3>{{$t('phasingPageLabels.description')}}</h3>
            <div id="adddescription">
                <div v-html="description" />
            </div>
          </div>
          <div class="modal-footer">
            <slot name="footer">
              <button class="modal-default-button button button-primary" @click="save" type="button" :disabled="isSaving">
                {{$t('general.save')}}
              </button>
              <button class="modal-default-button button button-secondary" @click="$emit('close')" type="button" :disabled="isSaving">
                {{$t('general.cancel')}}
              </button>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import FroalaEditor from "froala-editor";
import settings from "../../assets/settings.json";
import { get, post } from 'common/store/storeFunctions.js';
import { mapState } from "vuex";

export default {
    name: "addPhasing",
    props:["programId"],
    data: function() {
        return {
          phasingOptions: [],
          selectedPhasing: 0,
          description: "",
          config: null,
          editor: null,
          error: "",
          isSaving: false
        };
    },
    computed: {
      programPhasings() {
        if (this.acYears) {
          var acYear = this.acYears.currentAcademicYear;
          var params = this.$route.params;
          if (params.acYear) {
              acYear = params.acYear;
          }
          var key = acYear + params.programSlug;
          return this.program[key] ? this.program[key].phasings : null;
        } else {
            return null;
        }
      },
      filteredPhasingOptions(){
        if(!this.programPhasings){
          return [];
        }
        var phasingValues = this.programPhasings.map(x => x.PhasingValue);
       return this.phasingOptions.filter(x => !phasingValues.includes(x.Key)); 
      },
      ...mapState(["program"])
    },
    async created(){
      var model = this;
      
      this.$on("locale", lang => {
        model.config.language = lang;
        model.loadEditor();
      });

      var toolbar = {
        moreText: {
          buttons: ["bold", "italic", "underline", "strikeThrough", "subscript", "superscript", "clearFormatting"]
        },
        moreParagraph: {
          buttons: ["formatOLSimple", "formatUL", "paragraphFormat", "alignLeft", "alignCenter", "alignRight", "alignJustify", "outdent", "indent"]
        },
        moreRich: {
          buttons: ["insertLink",
          "insertVideo", "insertTable", "specialCharacters", "insertHR"],
          buttonsVisible: 4
        },
        moreMisc: {
          buttons: ["undo", "redo", "fullscreen", "help"],
          buttonsVisible: 4
        }
      };

      this.config = {
        events: {
          contentChanged: () => {
            if (model.editor.core.isEmpty()) {
              model.description = ""
            } else {
              var html = model.editor.html.get();
              if (html.includes("<iframe") || html.includes("<img")|| html.includes("<video")) {
                model.description = html;
              } else {
                var temp = document.createElement("div");
                temp.innerHTML = html;
                if (temp.innerText.trim() != "") {
                  model.description = html;
                } else {
                  model.description = ""
                }
              }
            }
          },
          "html.get": html => {
            var textualHtml = html;
            var currentHtml = model.$.parseHTML(html);

            while (currentHtml.length == 1 && currentHtml[0].nodeName == "DIV" && currentHtml[0].attributes.length > 0 && currentHtml[0].attributes[0].name.startsWith("data-v-")) {
              textualHtml = currentHtml[0].innerHTML
              currentHtml = model.$.parseHTML(textualHtml);
            }
            return textualHtml;       
          }
        },
        spellcheck: false,
        initOnClick: true,
        placeholderText: "",
        toolbarButtons: toolbar,
        imageUpload: false,
        imagePaste: false,
        videoUpload: false,
        imageEditButtons: ["imageReplace", "imageAlign", "imageRemove", "imageDisplay", "imageAlt"],
        tableEditButtons: ["tableHeader", "tableRows", "tableColumns", "-", "tableCells", "tableCellHorizontalAlign", "tableRemove"],
        language: model.$i18n.locale,
        imageDefaultAlign: "left",
        wordPasteModal: false,
        wordDeniedAttrs: ["style"],
        attribution: false,
        key: settings.froalaApi
      };
      model.phasingOptions = await get(`/api/phasinginfo/getoptions/${model.$i18n.locale == 'nl' ? 1043 : 1033}`)
    },
    mounted() {
      var model = this;
      this.$nextTick(() => {
        model.loadEditor();
      });
    },
    methods: {
      save: async function() {
        var model = this;
        model.isSaving = true;
        if(model.selectedPhasing === 0){
          model.error = "requiredError";
          return;
        }
        var phasing = {
          PhasingValue: model.selectedPhasing,
          Text: model.description
        }
        var acYear = this.acYears.currentAcademicYear;
        var params = this.$route.params;
        if (params.acYear) {
            acYear = params.acYear;
        }
        var key = acYear + params.programSlug;
        var updateKey = `program.${key}.phasings`;
        try {
          await post(`/api/phasinginfo/create/${model.programId}`, phasing);
          model.error = "";
          this.$store.commit("resetCacheForKey", updateKey);
          this.$store.dispatch("getPhasing", this.$route.params);
          model.$emit("close");
        } catch {
          model.error = "createError";	
        }
        model.isSaving = false;
      },
      loadEditor() {
        if (this.editor && this.editor.destroy) {
          this.editor.destroy();
        }
        this.editor = new FroalaEditor(`#adddescription`, this.config);
      },
    }
}
</script>
<style lang="less" scoped >
@import "../../assets/item.less";
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 80%;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
  max-height: 80vh;
  margin-top: 10%;
  margin-bottom: 10%;
  overflow-y: auto;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-header h2 {
    color: #25167a;
    font-size: 1.25em;
  }

.modal-body h3 {
  color: #000;
  font-size: 1em;
}

.modal-body select {
  margin-bottom: 25px;
}

.modal-body #adddescription .fr-view {
  min-height: 75px;
}

.modal-header,
.modal-body,
.modal-footer {
    padding-left: 0;
    padding-right: 0;
}

.modal-container {
    max-width: 1024px;
}

.modal-body {
  margin: 0;
}

.modal-default-button {
  float: right;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>