/* eslint-disable no-console */
export default {
    props: {
        header: String,
        value: undefined,
        edit: Boolean,
        hideForStudents: Boolean,
        extraClass: String
    },
    data: function() {
        return {
            revert: null
        };
    },
    created() {
        var model = this;
        // if using this when emitting use $event.$emit instead of $emit
        this.$event.$on("revert", () => {
            model.onRevert();
        })
        this.$event.$on("save", () => {
            model.onSave();
        })
        this.revert = this.value;
        this.toOriginal();
    },
    methods: {
        onDirty() {
            this.$event.$emit("dirty");
        },
        onRevert() {
            this.toOriginal();
        },
        onSave() {
            //This method should be overwritten by the components implementing this mixin
            //update this.revert
        },
        toOriginal() {
            //This method should be overwritten by the components implementing this mixin
            console.log("no toOriginal found")
        }
    }
}