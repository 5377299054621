<template>
    <span>
    <img v-show="info != null" class="infoBlueIcon" :id="id + 'tooltip'" src="../../images/Info_blue.svg" />
    <b-tooltip v-show="info != null" ref="tooltip" :target="id + 'tooltip'" placement="right">
        <span v-html="info"></span>
    </b-tooltip>
    </span>
</template>
<script>
    /*eslint-disable */
    export default {
        props: {
            id: String,
            inBlock: Boolean
        },
        data: function () {
            return {
                info: null
            };

        }, created() {
            var model = this;
            var infoKey = "CourseCatalog.";
            if (model.isExchange) {
                infoKey = "ExchangeProgram.";
            }
            var parent = model.$parent;
            if (model.inBlock) {
                parent = model.$parent.$parent;
            }
            var extraInfos;
            if (parent.$parent.programBaseitem != null) {
                infoKey = infoKey + "Course.";
                infoKey = infoKey + parent.$attrs.valuePath.replace("CourseCatalogItem.", "");
                extraInfos = parent.$parent.programBaseitem.CourseCatalogItem.ExtraInfos;
            } else {
                infoKey = infoKey + "Program.";
                var keyPart = parent.$attrs.valuePath;
                if (!keyPart) {
                    keyPart = model.id;
                }
                infoKey = infoKey + keyPart.replace("Info.", "");
                extraInfos = parent.$parent.programDetails.Info.ExtraInfos;
            }
            if (extraInfos) {
                var extraInfo = extraInfos.filter(el => el.Key == infoKey);
                if (extraInfo.length > 0) {
                    model.info = extraInfo[0].Value;
                }
            }
        }
    }
</script>

<style lang="css">
    #app span img.infoBlueIcon{
        width:15px;
        height:15px;
        margin-left:5px;
        margin-top:-4px;
        cursor:pointer;
        display:inline-block;
    }

    .tooltip:focus {
        outline:0;
    }

    .tooltip {
        position: absolute;
        z-index: 1070;
        display: block;
        margin: 0;
        margin-left: 5px;
        font-family: 'Open Sans',Segoe UI,Segoe,Tahoma,Helvetica,Arial,sans-serif;
        font-style: normal;
        font-weight: 400;
        line-height: 1.5;
        text-align: left;
        text-align: start;
        text-decoration: none;
        text-shadow: none;
        text-transform: none;
        letter-spacing: normal;
        word-break: normal;
        word-spacing: normal;
        white-space: normal;
        line-break: auto;
        font-size: 0.875rem;
        word-wrap: break-word;
        width: 350px;
    }

        .tooltip .arrow {
            position: absolute;
            display: block;
            width: 15px;

        }

    .bs-tooltip-right {
        padding: 0 5px;
    }

        .bs-tooltip-right .arrow::before {
            left: -1px;
            top:-9px;
            border-width: 12px 12px 12px 0;
            border-color: transparent;
            border-right-color: #ddd;
        }
        .bs-tooltip-right .arrow::after {
            left: -1px;
            top:-9px;
            border-width: 11px 11px 11px 1px;
            border-color: transparent;
            border-right-color: #fafafa;
        }
        
        .bs-tooltip-right .arrow {
            left: 0;
            width: 3px;
            height: 3px;
        }

    .tooltip .arrow::before,
    .tooltip .arrow::after{
        position: absolute;
        content: "";
        border-style: solid;
    }

    .tooltip.b-tooltip {
        opacity: 1;
    }

    div.tooltip div.tooltip-inner {
        background-color: #fafafa;
        border: solid 1px #ddd;
        border-radius: 3px;
        padding: 5px 10px;
        margin-left:5px;
    }
</style>