<template>
	<div>
		<div class="aside-menu">
			<h3 class="educationHeader">{{$t("menuLabels.theEducation")}}</h3>
			<ul class="link-list" v-if="!loading">
				<li>
					<div>
						<router-link class="hva-caret-right program-overview-link" :to="programRoute">{{$t("menuLabels.Education")}}</router-link>
					</div>
				</li>
				<li v-for="(item, index) in phasingRoutes" :key="index">
					<div v-if="!item.route">
						<p :id="index" class='hva-caret-right phasing-header' v-on:click="toggle(index)">{{item.label[$t("phasingPageLabels.languageCode")]}}</p>
						<li v-for="(subphasing, sindex) in item.subphasings" :key="sindex">
							<router-link :name="index" :class="$route.params.phaseValue == subphasing.route.params.phaseValue ? 'hva-caret-right router-link-active subphasing' : 'hva-caret-right subphasing' " :to="subphasing.route">{{subphasing.label}}</router-link>
						</li>
					</div>
					<div v-else>
						<router-link :class="$route.params.phaseValue == item.route.params.phaseValue ? 'hva-caret-right router-link-active' : 'hva-caret-right' " :to="item.route">{{item.label}}</router-link>
					</div>
				</li>
			</ul>
			<a class="addphasing" v-if="userCanEditProgram" href="#" @click="showModal = true;"><font-awesome-icon icon="plus" />{{$t('phasingPageLabels.addPhasing')}}</a>
			<h3 v-if="!isExchange" class="educationHeader">{{$t("menuLabels.moreInformation.header")}}</h3>
			<ul v-if="!isExchange" class="link-list">
				<li v-if="programDetails && programDetails.Info.LinkToHonours">
					<div>
						<a class="hva-caret-right" :href="linkToHonours" target="_blank">{{$t("menuLabels.moreInformation.labels.honours")}}</a>
					</div>
				</li>
				<li v-if="programDetails && programDetails.Info.LinkToMinors">
					<div>
						<a class="hva-caret-right" :href="linkToMinors">{{$t("menuLabels.moreInformation.labels.minors")}}</a>
					</div>
				</li>
				<li>
					<div>
						<router-link class="hva-caret-right" to="/archief">{{$t("menuLabels.moreInformation.labels.archive")}}</router-link>
					</div>
				</li>
				<li>
					<div>
						<router-link class="hva-caret-right" to="/intellectueeleigendom">{{$t("menuLabels.moreInformation.labels.ip")}}</router-link>
					</div>
				</li>
			</ul>
		</div>
		<addPhasing v-if="userCanEditProgram && programDetails" v-show="showModal" :programId="programDetails.Id" @close="showModal = false" />
	</div>
</template>
<script>
/*eslint-disable */
import { mapState } from "vuex";
import AddPhasing from "./AddPhasing"

export default {
	name: "side-menu",
	props:{
		userCanEditProgram: Boolean
	},
	components:{
		AddPhasing
	},
	data: function() {
        return {
			showModal: false
		};
	},
	methods:{
		toggle(id){
			var header = document.getElementById(id);
			if(header.classList.contains("hva-caret-down")){
				header.classList.remove("hva-caret-down")
			}else{
				header.className += " hva-caret-down"
			}
			var subphasings = document.getElementsByName(id);
			subphasings.forEach(x => {
				if(x.style.display == "block"){
					x.style.display = "none";
				}else{
					x.style.display = "block";
				}
			});
		},
		update(){
			var child = document.getElementsByClassName("router-link-active subphasing")[0]
			if(child){
				var parent = document.getElementById(child.name)
				parent.className += " hva-caret-down"
				parent.parentNode.childNodes.forEach(x => {
					if(x.nodeName == "LI"){
						x.firstChild.style.display = "block";
					}
				})
			}
		}
	},
	computed: {
		linkToHonours() {
			if(!this.programDetails.Info.LinkToHonours) return
			if(this.programDetails.Info.LinkToHonours.startsWith("http")) {
				return this.programDetails.Info.LinkToHonours
			} else {
				return "https://" + this.programDetails.Info.LinkToHonours
			}
		},
		
		linkToMinors() {
			if(!this.programDetails.Info.LinkToMinors) return
			if(this.programDetails.Info.LinkToMinors.startsWith("http")) {
				return this.programDetails.Info.LinkToMinors
			} else {
				return "https://" + this.programDetails.Info.LinkToMinors
			}
		},
		programDetails() {
			 if (this.acYears) {
                var acYear = this.acYears.currentAcademicYear;
                var params = this.$route.params;
                if (params.acYear) {
                    acYear = params.acYear;
                }
                var key = acYear + params.programSlug;
                return this.program[key] ? this.program[key].details : null;
            } else {
                return null;
            }
		},
		programPhasings() {
			if (this.acYears) {
                var acYear = this.acYears.currentAcademicYear;
                var params = this.$route.params;
                if (params.acYear) {
                    acYear = params.acYear;
                }
                var key = acYear + params.programSlug;
                return this.program[key] ? this.program[key].phasings : null;
            } else {
                return null;
            }
		},
		programRoute() {
			if (this.programDetails) {
				var slug = this.$route.params.programSlug;
				if (this.$route.params.acYear){
					return { name: "program-overview", params: { programSlug: slug, acYear: this.$route.params.acYear } };
				}else{
					return { name: "program-overview", params: { programSlug: slug } };
				}
			}
			return "/";
		},
		phasingRoutes() {
			var model = this;
			var phasing = this.programPhasings;
			var program = this.programDetails;
			if (phasing && program) {
				if(program.SortPhasingAndGroupings){
					var sort = JSON.parse(program.SortPhasingAndGroupings);
				}
				var id = this.$route.params.programSlug;

				var links = [];
				if (program.ManualSorting && sort) {
					var kv = {};
					phasing.forEach(function(p) {
						kv[p.PhasingValue] = p.PhasingLabel;
					});
					if (this.$store.getters.isStudiegids && !this.isExchange) {
						for (var i in sort.studiegids.phasings) {
							var phase = sort.studiegids.phasings[i];
							if (kv[phase]) {
								if (model.$route.params.acYear){
									links.push({
										route: { name: "phasing-overview", params: { programSlug: id, phaseValue: phase, acYear: model.$route.params.acYear } },
										label: kv[phase]
									});
								}else{
									links.push({
									route: { name: "phasing-overview", params: { programSlug: id, phaseValue: phase } },
									label: kv[phase]
								});
								}
							}else if(phase.subphasings){
								var sublinks = [];
								phase.subphasings.forEach(x => {
									if (model.$route.params.acYear){
										sublinks.push({
											route: { name: "phasing-overview", params: { programSlug: id, phaseValue: x, acYear: model.$route.params.acYear } },
											label: kv[x]
										});
									}else{
										sublinks.push({
										route: { name: "phasing-overview", params: { programSlug: id, phaseValue: x } },
										label: kv[x]
										});
									}
								});
								
								links.push({
									label: {nl: phase.nl, en: phase.en},
									subphasings: sublinks
								})
							}
						}
					} else {
						for (var i in sort.courseselector.phasings) {
							var phase = sort.courseselector.phasings[i];
							if (kv[phase]) {
								if (model.$route.params.acYear){
									links.push({
										route: { name: "phasing-overview", params: { programSlug: id, phaseValue: phase, acYear: model.$route.params.acYear } },
										label: kv[phase]
									});
								}else{
									links.push({
									route: { name: "phasing-overview", params: { programSlug: id, phaseValue: phase } },
									label: kv[phase]
								});
								}
							}
						}
					}
				} else {
					if (model.$route.params.acYear){
						phasing.forEach(function(phase) {
							links.push({
								route: { name: "phasing-overview", params: { programSlug: id, phaseValue: phase.PhasingValue, acYear: model.$route.params.acYear} },
								label: phase.PhasingLabel
							});
						});
					}else{
						phasing.forEach(function(phase) {
							links.push({
								route: { name: "phasing-overview", params: { programSlug: id, phaseValue: phase.PhasingValue } },
								label: phase.PhasingLabel
							});
					});

					}
				}
				return links;
			}
			return [];
		},
		phaseNewRoute() {
			return { name: "phase-new", params: { programId: this.$route.params.programId } };
		},
		loading() {
			return this.programDetails == null || this.programPhasings == null;
		},
		...mapState(["program"])
	},
	created() {
		var params = this.$route.params;
		this.$store.dispatch("getProgram", params);
		this.$store.dispatch("getAcYearsForProgram", params);
		this.$store.dispatch("getPhasing", params);
	},
	
	watch:{
		$route (to, from){
			this.update();
		}
	},
	mounted(){
		this.update();
	}
};
</script>
<style lang="less" scoped>
#app {
	h3.educationHeader {
		font-family: "Open Sans", Helvetica, Arial, sans-serif;
		font-size: 15px;
		font-weight: 400;
		line-height: 1.25;
		text-transform: uppercase;
		color: #999999;
		margin-bottom: 15px;
	}

	ul.link-list li div a {
		padding-bottom: 10px;
		font-size: 15px;
	}

	svg.menu-icon {
		font-size: 22px;
		color: #25167a;
		width: 22px;
	}

	ul.link-list li div a {
		width: 100%;
		display: block;
		word-break: break-word;
		padding-left: 26px;
		color: #000;
	}

	a.router-link-exact-active {
		color: #25167a;
	}

	a.router-link-exact-active::before {
		border-left-color: #25167a;
	}

	a.router-link-active:not(.program-overview-link) {
		color: #25167a;
	}

	a.router-link-active:not(.program-overview-link)::before {
		border-left-color: #25167a;
	}

	ul.link-list {
		padding-left: 0px;
	}

	.extraFasering a {
		color: #000;
		font-size: 15px;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.link-list{
		margin-bottom: 15px;
	}
	.addphasing{
		display:block;
		margin-top: -15px;
		margin-bottom: 25px;
	}
	.phasing-header{
		padding: 0px 0px 10px 26px;
		color:#000;
		margin: 0px;
		
	}
	.phasing-header:hover{
		text-decoration: underline;
		cursor: pointer;
	}
	a.hva-caret-right.subphasing{
		padding-left: 52px !important;
		display: none;
	}
}
</style>
