<template>
	<div class="row top-row">
		<div class="col-md-3">
			<div class="list-return">
				<router-link class="hva-caret-left" :to="programListRoute">{{$t("menuLabels.list")}}</router-link>
			</div>
		</div>
		<div class="col col-md-9">
			<div v-if="hasProp(program)">
				<div class="program-header">
					<h1>{{program.Title}}</h1>
					<div v-if="isEditing">
						<font-awesome-icon icon="share-alt" class="shareLink fontAwesome fontAwesome-studiegids" :title="$t('general.shareEditorPage')" @click="getEditorShareUrl" />
					</div>
				</div>
			</div>
			<div v-if="hasProp(baseitem)">
				<div class="course-header redit">
					<h2>{{baseitem.Title}}</h2>
					<div v-if="userCanEditCourses && isEditing && !futureEditMode" class="icons">
						<router-link :to="copyRoute">
							<font-awesome-icon icon="copy" class="clone-course fontAwesome fontAwesome-studiegids" :title="$t('clonePage.title')" />
						</router-link>
					</div>
					<div v-if="isEditing">
						<font-awesome-icon icon="share-alt" class="shareLink fontAwesome fontAwesome-studiegids" :title="$t('general.shareEditorPage')" @click="getEditorShareUrl" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: "overview-header",
	props: ["baseitem", "program", "isEditing", "futureEditMode"],
	computed: {
		userCanEditCourses() {
			return  (this.userIsCourseEditor || this.userIsCourseFinalEditor);
		},
		copyRoute() {
			return {
				name: "clone",
			};
		},
		programListRoute(){
			var deepLink = this.$store.state.deeplink;
			if(deepLink){
				return { name: "homeDeepLinked", params: { deepLink: deepLink } };
			}
			return { name: "home" };
		}
	},
	methods: {
		hasProp(prop) {
			return !(prop == null || prop == undefined);
		},
		toHelpScreen: function() {
			var model = this;
			window.open(this.$i18n.locale == "en" ? model.helpUrlEn : model.helpUrlNl, "_blank");
		},
		getEditorShareUrl(){
			this.$root.$children[0].getEditorShareUrl();
		}
	}
};
</script>
<style lang="less" scoped>
#app {
	.top-row {
		min-height: 90px;
	}
	h1 {
		font-family: "Open Sans", Helvetica, Arial, sans-serif;
		font-size: 26px;
		font-weight: 400;
		display: block;
		color: #25167a;
		margin: 25px 0 !important;
	}

	.program-header {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		margin: 11px 0px;
		min-height: 31px;
	}

	h2 {
		font-family: "Open Sans", Helvetica, Arial, sans-serif;
		font-size: 22px;
		font-weight: 400;
		display: block;
		color: #666;
		letter-spacing: 0.03em;
		padding: 0px;
		margin: 11px 0px;
		min-height: 31px;
	}

	.list-return {
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 36px 0 11px 0;
		min-height: 31px; /* Align with program-header in height */
	}

	.list-return a {
		font-size: 15px;
		font-family: "Open Sans", Helvetica, Arial, sans-serif;
		padding-left: 26px;
		width: 100%;
		display: block;
		color: #25167a;
	}

	.list-return a:hover {
		text-decoration: none;
	}

	.list-return a::before {
		margin-left: -32px;
	}

	.icons {
		width: 20%;
		display: flex;
		justify-content: flex-end;
	}
	div.vueTitleContainer {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}
	div.vueTitleContainer > h1#pageTitleVue {
		padding-left: 0px;
		padding-bottom: 15px;
	}
	button.helpButton {
		margin: 0 0 25px 5px;
	}
	.shareLink,
	.clone-course {
		background-color: #007C68;
		padding: 6px;
		border-radius: 3px;
		color: #fff;
		float: right;
		height: 28px;
		width: 28px !important;
		margin-top: -2px;
		cursor:pointer;
	}
}
</style>
