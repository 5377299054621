<template>
    <div class="mt-4">
        <div class="karakters ms-metadata">
            <span>{{$t("general.fileExplanation")}}</span>
        </div>
        <div class="input-group filevalidation">
            <div class="custom-file">
                <input type="file"
                    class="custom-file-input"
                    aria-describedby="inputGroupFileAddon01"
                    accept="image/*"
                    @change="setFile($event)"
                    :value="value"
                    id="file-input">
                <label class="custom-file-label" for="inputGroupFile01">{{imageName}}</label>            
            </div>
        </div>
    </div>
</template>

<script>
/*eslint-disable */


export default {
    name : "extra-image-upload",
    props: {
        imageUrl: String, 
        value: String
    },
    data() {
        return {
            imageName : this.$i18n.t("general.chooseFile"),
            previewImage : null,
            data: null,
            }
    },
    methods: {
         setFile(file) {
            const image = file.target.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(image);
            reader.onload = file => {
                if (!file) {
                    return;
                }
                this.previewImage = file.target.result;
                this.backupImg = this.previewImage;
                var payload = {
                    name: image.name,
                    image: this.previewImage
                };
                this.$emit('fileChange', payload);
                this.imageName = image.name;
            }
         }
    }

}
</script>

<style>
    .filevalidation {
        margin-bottom: 25px;
    }
</style>