import { render, staticRenderFns } from "./AddPhasing.vue?vue&type=template&id=40e1778b&scoped=true"
import script from "./AddPhasing.vue?vue&type=script&lang=js"
export * from "./AddPhasing.vue?vue&type=script&lang=js"
import style0 from "./AddPhasing.vue?vue&type=style&index=0&id=40e1778b&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../TweeAT.HvA.CourseCatalog/ClientApp/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40e1778b",
  null
  
)

export default component.exports